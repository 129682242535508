import React from 'react';

const Banner = () => {
    return <section id="banner" className="banner component component-banner">
        <h2>Academia Pedro Martinez</h2>
        <p>
            Desde de Novembro de 2013, a academia vem utilizando o jiu jitsu para ajudar pessoas a alcançarem seus
            objetivos.
        </p>
        <ul className="actions">
            <li><a href="/#sobre" className="button special big">Saiba Mais</a></li>
        </ul>
    </section>
}

export default Banner;