import axios from "axios";

const ENV = process.env.NODE_ENV;
// const ENV = "production";

const config = {
  development: {
    API_URL: "http://localhost:3000/site",
  },
  production: {
    API_URL: "https://apm-api.herokuapp.com/site",
    // API_URL: "https://apm-api.azurewebsites.net/site",
  },
};

const request = axios.create({
  baseURL: config[ENV].API_URL,
});

const getTreinos = async (params) => {
  return await request.get("/treinos", {
    params: params,
  });
};

const getProfessores = async (params) => {
  return await request.get("/professores", {
    params: params,
  });
};

const getModalidades = async (params) => {
  return await request.get("/modalidades", {
    params: params,
  });
};

const getPlanos = async (params) => {
  return await request.get("/planos", {
    params: params,
  });
};

const getProdutos = async (params) => {
  // console.log("getProdutos", params);

  return await request.get("/produtos", {
    params: params,
  });
};

const api = {
  getTreinos,
  getProfessores,
  getModalidades,
  getPlanos,
  getProdutos,
};

export default api;
