import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/scss/index.css";
import ReactGA from "react-ga4";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { Loader } from "./components";

const LazyApp = lazy(() => import("./App"));

ReactGA.initialize("G-ZTVEFVNP2E");

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Loader />}>
      <LazyApp />
    </Suspense>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
