import React from 'react';
import styled from "styled-components";
import { Spinner } from 'reactstrap';

const LoaderWrapper = styled.div`
    background: rgba(256, 256, 256, .5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Loader = props => {
    const { visible } = props;

    return visible ? <LoaderWrapper>
        <Spinner />
    </LoaderWrapper> : null
}

export default Loader;