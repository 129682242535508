import React from 'react';
import classnames from 'classnames';

const Wrapper = props => {
    return <section id={props.name} className={`wrapper ${classnames({
        'style1': props.style1,
        'style2': props.style2,
        'style3': props.style3,
        'style4': props.style4,
        'special': props.special
    })}`}>
        <div id={props.name} className="container">{props.children}</div>
    </section>
}

export default Wrapper;