import React, { useState } from "react";
import "./header.css";

const Links = () => {
  return (
    <ul className="links">
      <li>
        <a href="/#sobre">Sobre</a>
      </li>
      <li>
        <a href="/#modalidades">Modalidades</a>
      </li>
      <li>
        <a href="/#professores">Professores</a>
      </li>
      <li>
        <a href="/#horarios">Horários</a>
      </li>
      <li>
        <a href="/#checkin">Check-in</a>
      </li>
      <li>
        <a href="/#experimental">Aula Experimental</a>
      </li>
      <li>
        <a href="/#contato">Contato</a>
      </li>
    </ul>
  );
};

const Header = () => {
  const [showNavBar, setShowNavBar] = useState(false);

  return (
    <>
      <header id="header" className="alt">
        <nav id="alt">
          <Links />
        </nav>
      </header>

      <button
        href="#menu"
        onFocus={() => setShowNavBar(true)}
        onBlur={() => setShowNavBar(false)}
        className="navPanelToggle"
      >
        <span className="fa fa-bars"></span>
      </button>

      <div id="navPanel" className={showNavBar ? "visible" : ""}>
        <Links />
        <button href="#navPanel" className="close"></button>
      </div>
    </>
  );
};

export default Header;
